module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://44bb6b1dd35847359ddd0fa3b902f02f@sentry.io/1781559","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Psychotherapie Anna Weber-Nilges","short_name":"Anna Weber-Nilges","start_url":"/","background_color":"#fff","theme_color":"#ff5975","display":"standalone","legacy":true,"icon":"static/images/weni-favicon.svg","icons":[{"src":"favicon/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicon/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicon/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicon/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicon/icon-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"theme_color_in_head":true,"cacheDigest":"a0e4a2b8707c27b3067b6d263befdb8c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
