// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-behandlungsspektrum-jsx": () => import("./../../../src/pages/behandlungsspektrum.jsx" /* webpackChunkName: "component---src-pages-behandlungsspektrum-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-einzeltherapie-jsx": () => import("./../../../src/pages/einzeltherapie.jsx" /* webpackChunkName: "component---src-pages-einzeltherapie-jsx" */),
  "component---src-pages-gruppentherapie-jsx": () => import("./../../../src/pages/gruppentherapie.jsx" /* webpackChunkName: "component---src-pages-gruppentherapie-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-ueber-mich-jsx": () => import("./../../../src/pages/ueber-mich.jsx" /* webpackChunkName: "component---src-pages-ueber-mich-jsx" */)
}

