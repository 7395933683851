import './src/styles/global.scss'

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

const disableStr = `ga-disable-${process.env.GATSBY_GA_PROPERTY_ID}`;
if (document.cookie.indexOf(disableStr) > -1) {
    window[disableStr] = true;
}
window.gaOptOut = function() {
    document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    window[disableStr] = true;
    alert('Google Analytics wurde für diese Seite deaktiviert.');
}
